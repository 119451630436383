.bet-amount-input {
    display: flex;
    align-items: center;
    height: 46px;

    .input-container {
        width: 50%;
        height: 100%;
    }

    .border {
        height: 2px;
        box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
        background-image: linear-gradient(to right, #4e2d49 20%, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49 85%);
    }

    .input {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: 0;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 0.7em;
        background-color: #0E092F;
        outline: none;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    button {
        height: 100%;
        padding: 0;
        background-color: transparent !important;
        color: #fff !important;
        width: 25%;
        background-size: 100% 130%;
        min-width: unset;
        font-size: 1.5em;

        &.minus {
            background-image: url(../images/minus-button.png);
            margin-right: -5px;
        }

        &.plus {
            background-image: url(../images/plus-button.png);
            margin-left: -5px;
        }
    }
}