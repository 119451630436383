.game {
    max-width: 375px;
    min-width: 375px;
    width: 375px;
    max-height: 752px;
    min-height: 752px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    flex-direction: column;

    #keno-logo {
        width: 100px;
        height: 45px;
        margin: 3px 0;
    }

    .title {
        text-align: center;
    }

    .bank {
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        input {
            margin-left: 10px;
            height: 30px;
            font-size: 15pt;
        }
    }

    .game-tables {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-top: 30px;
    }
}