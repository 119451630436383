body {
  background-image: url(assets/images/background.png);
  background-size: cover;
}

#root {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page-root {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.page-container {
  height: 100%;
}

.navbar-root {
  header {
    box-shadow: none;
  }

  .home-container {
    flex-grow: 1;
    justify-content: left;
  }

  .tool-bar {
    padding-left: 0;
    padding-right: 0;
  }
}
