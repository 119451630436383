.winnings-status {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: -40px;
    flex: 1 0 auto;
    height: 152px;

    .winning {
        background: #050711;
        color: #fff;
        text-align: center;
        padding: 5px 10px;
        border-radius: 10px;
        width: fit-content;
        height: fit-content;

        h4 {
            color: #25f915;
            margin: 0;
        }

        h3 {
            margin: 0;
            margin-top: 5px;
        }
    }

    .table-container {
        max-width: 125px;
    }

    &.thirty {
        margin-bottom: -40px;

        .payout-table {
            flex: 1;
        }
    }

    &.fifty {
        margin-bottom: -50px;
    }
}
