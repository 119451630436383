.table-container {
    flex: 1;
    padding: 0 10px;

    .title {
        color: #29b8ec;
        font-weight: bold;
        font-family: Roboto;
        font-size: 0.7em;
        padding: 3px;
        padding-top: 6px;
        text-transform: uppercase;
    }

    .title-border {
        width: 100%;
        height: 3px;
        box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
        background-image: linear-gradient(to top, #4e2d49, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49);
        margin-bottom: -3px;
    }

    .header-container {
        display: flex;
        flex-direction: column;

        .title {
            background-color: #07031a;
        }

        .border {
            width: 100%;
            height: 2px;
            box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
            background-image: linear-gradient(to right, #4e2d49 20%, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49 85%);
        }

        .headers {
            display: flex;
            justify-content: space-between;
            border-radius: 0 0 4px 4px;
            overflow: hidden;

            .heading {
                padding: 5px;
                flex: 1;
                text-align: center;
                font-family: Roboto;
                font-weight: 700;
                font-size: 0.7em;
                color: #ffffff;
                text-transform: uppercase;
                background-color: #3123bd;
            }
        }
    }

    .data {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;

        .row {
            display: flex;
            flex: 1;

            .col {
                position: relative;
                flex: 1;
                text-align: center;
                background-color: rgba(18, 31, 63, 0.5);
                color: #ffffff;
                margin: 1px 0;
                padding: 3px;
                overflow: visible;
                font-size: 0.7em;

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                &.separator {
                    &::after {
                        z-index: 2;
                        content: ' ';
                        width: 10px;
                        height: 1px;
                        background-color: rgba(255, 255, 255, 0.3);
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(50%, -50%);
                    }
                }
            }
        }
    }
}