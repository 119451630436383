.app-header {
    background-color: #371bc3;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-image: url(../images/container-highlights.png);
    background-repeat: no-repeat;
    background-size: 100% calc(100% + 1px);
    background-position-y: 1px;

    .items {
        flex: 1 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .item {
            width: 30%;
            text-align: center;

            img.icon {
                width: 30%;
                height: 30%;
                margin-right: -15px;
                margin-top: -20px;
                z-index: 2;
            }

            .inputs {
                display: flex;
                align-items: center;

                .value-wrapper {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    span.value {
                        height: 24px;
                        width: 90%;
                        background-color: #07031a;
                        color: #fff;
                        text-align: center;
                        font-weight: 600;
                        border: 0;
                        border-radius: 10px;
                        padding: 3px;
                        font-size: 0.8em;
                        font-family: Roboto;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 24px;
                        user-select: none;
                        letter-spacing: normal;
                        text-align: center;
                        color: #ffffff;
                        background-image: url(../images/textbox.png);
                    }
                }

                div.label {
                    font-family: Roboto;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    color: #43b9eb;
                    margin-top: 5px;
                    font-size: 0.7em;
                }
            }
        }

        .game-id {
            font-size: 7pt;
            font-family: Roboto;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            min-width: 50px;
            background-image: url(../images/game-id.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 0 7px 12px 7px;
            align-self: start;
            margin-top: 3px;
        }
    }
}