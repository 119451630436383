.error-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 300px;
    height: 200px;
    background-color: #3123bd;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    outline: none;
    user-select: none;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    box-sizing: border-box;

    .message {
        border-bottom: 2px solid #4537d0;
        padding: 30px 0;
    }

    button {
        width: 100px;
        color: #ffffff;
        font-weight: bold;
        font-size: 10pt;
        font-family: 'M PLUS Rounded 1c';
        font-weight: 900;
        background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);
    }
}