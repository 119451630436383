.game-actions {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    box-sizing: border-box;
    background-image: url(../images/bottom-container.png);
    background-repeat: no-repeat;
    background-size: 100% calc(100% + 20px);
    box-sizing: border-box;
    background-position: 0 -20px;

    .top {
        padding: 0 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;

        button.purple-button {
            color: #fff;
            font-weight: bold;
            font-size: 10pt;
            font-family: 'M PLUS Rounded 1c';
            font-weight: 900;
            padding: 10px;
            min-width: unset;
            background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);

            .label {
                border-right: 1px solid #00000030;
                padding-right: 5px;
                margin-right: 5px;
            }
        }

        .bet-amount-input {
            flex: 2;
        }

        .drop-up-button-wrapper {
            flex: 1;
            margin: 0 1px;

            button {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.7em;
            }
        }

        .drop-up-button-wrapper, button.purple-button {
            flex: 1;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.7em;
        }

        .quick-pick {
            flex: 2;
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        padding: 0 5px;
        box-sizing: border-box;

        button {
            width: 30%;
            color: #fefffe;
            font-size: 0.8em;
            font-weight: bold;
            padding: 2% 0;
            font-weight: bold;
            font-family: 'M PLUS Rounded 1c';
            font-size: 0.8em;
            background-image: linear-gradient(to top, #69ba20 5%, #5aac24 5%, #b3fb13);

            &.Mui-disabled {
                background-color: grey;
            }

            .pipe {
                margin: 0 5px;
            }

            .label {
                border-right: 1px solid #00000030;
                padding-right: 10px;
                margin-right: 10px;
                font-size: 1em;
            }

            .number {
                font-size: 15pt;
                background-color: #398310;
                width: 33%;
                height: 33%;
                line-height: 30px;
                border-radius: 50%;
            }
        }
    }

    .highlights {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-top: -25px;
        height: 20px;

        &.bottom {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
        }
    }

    .highlight-border {
        height: 4px;
        box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
        background-image: linear-gradient(to right, #4e2d49 20%, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49 85%);
        margin-bottom: 20px;
        width: 100%;
        background: rgb(78,45,73);
        background: linear-gradient(90deg, rgba(78,45,73,1) 6%, rgba(180,125,63,1) 20%, rgba(219,190,60,1) 33%, rgba(252,241,132,1) 46%, rgba(252,241,132,1) 53%, rgba(219,190,60,1) 61%, rgba(180,125,63,1) 78%, rgba(78,45,73,1) 100%);

        &.bottom {
            margin-bottom: 0;
            margin-top: 20px;
        }
    }
}
