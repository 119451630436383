.drop-up-button-wrapper {
    position: relative;

    button.drop-up-button {
        height: 100%;
        width: 100%;
        color: #fff;
        font-weight: bold;
        font-size: 10pt;
        font-family: 'M PLUS Rounded 1c';
        font-weight: 900;
        letter-spacing: 1.5px;
        padding: 10px;
        background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);
        position: relative;

        .label {
            border-right: 1px solid #00000030;
            padding-right: 0;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .drop-up-img {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            margin-top: -16px;
            height: 25px;
        }
    }

    .drop-up {
        width: 100%;
        position: absolute;
        z-index: 3;
        bottom: 100%;
        margin-bottom: 20px;

        .option {
            cursor: pointer;
            background-color: #9f66ff;
            user-select: none;
            color: #fff;
            font-size: 11pt;
            font-family: 'M PLUS Rounded 1c';
            font-weight: 900;
            height: 56px;
            line-height: 56px;
            text-align: center;
            box-shadow: 0px 1px 0 0 rgba(189, 134, 255, 0.7), inset 0px 1px 0 0 rgba(99, 0, 198, 0.35);

            &.active, &:hover {
                background-color: #9158f1;
            }
        }

        & + button {
            .drop-up-img {
                transform: rotate(180deg);
                margin-top: -30px;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }
}