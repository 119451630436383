.preloader {
    max-width: 375px;
    min-width: 375px;
    width: 375px;
    max-height: 752px;
    min-height: 752px;
    height: 752px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/loader-start.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

.preloader-text {
    color: #333399;
    font-weight: 700;
    font-size: 26px;
    margin-top: 20px;
    &:after {
        content: '.';
        animation: dots 1.5s steps(5, end) infinite;
    }
}

@keyframes dots {
    0%,
    20% {
        color: #333399;
        text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: #333399;
        text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow:
            0.25em 0 0 #333,
            0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
        text-shadow:
            0.25em 0 0 #333,
            0.5em 0 0 #333;
    }
}
