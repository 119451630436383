.game-menu-wrapper {
    position: relative;
    width: 14%;

    .menu {
        justify-self: flex-end;
        padding: 0;
        margin-right: -1px;
        width: 50px;
        height: 40px;
        min-width: unset;
        color: #fff;
        font-size: 23pt;
        background-color: #8f56ff;
        background-color: transparent;
        box-sizing: border-box;
        background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);

        .MuiButton-label {
            height: inherit;
        }

        img {
            width: 60%;
            height: 60%;
        }
    }

    .menu-content-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 180px;
        min-height: 160px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        
        .border {
            height: 3px;
            box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
            background-image: linear-gradient(to top, #4e2d49, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49);
        }

        .reply {
            cursor: pointer;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            margin-left: -35px;
            background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);
            padding: 5px;
            border-radius: 4px 0 0 4px;
        }

        .title {
            background: #0e0b40;
            font-size: 8pt;
            font-family: Roboto;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #43b9eb;
            text-transform: uppercase;
            padding: 10px;
            user-select: none;
        }

        .contents {
            padding:  0 19px;
            background: #3124bd;
            font-family: Roboto;
            color: #ffffff;
            flex: 1 0 auto;

            .sound-toggle-container {
                font-family: Roboto;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
                font-size: 15px;
                border-bottom: 1px solid rgb(69, 55, 208);
                padding: 24px 0;
                user-select: none;
                display: flex;
                justify-content: center;
                align-items: center;

                .button-wrapper-parent {
                    margin-left: 10px;
                    width: unset;
                }
            }
        }

        .buttons {
            padding:  19px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .button-wrapper-parent {
                width: 100%;

                button {
                    width: 100%;
                }
            }

            button {
                background-image: linear-gradient(to top, #8f55ff, #a66dff), linear-gradient(to bottom, #8f55ff, #8f55ff);
                color: #fff;
                font-size: 10pt;
                font-family: 'M PLUS Rounded 1c';
                font-weight: 900;
                letter-spacing: 1.5px;
                margin-bottom: 10px;
            }
        }

        .game-id {
            font-family: Roboto;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-bottom: 10px;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.6);
    }
}