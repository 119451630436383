
.hopper {
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .ball-hopper-wrapper {
        width: 120px;
        height: 120px;
        position: relative;

        #ball-hopper {
            width: 120px;
            clip: rect(0px, 120px, 46px, 0px);
            position: absolute;
            bottom: 0;
            margin-bottom: -15px;
        }
    }

    .ball-wrapper {
        position: absolute;

        .ball {
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            margin-left: -4px;
        }

        .number {
            font-weight: bold;
            font-size: 17.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #170d45;
            position: absolute;
            top: 0;
        }
    }
}

.grow {
    transition: all 0.6s ease-in-out;
    animation: grow 1s ease-in-out;
}
  
@keyframes grow {
    0% {
        transform: scale(1);
    }
    50%  {
        transform: scale(3);
    }
    100% {
        transform: scale(1);
    }
}