$number-size: 45px;

.perfect-square {
    width: 95%;
    position: relative;
}

.board-numbers-layer-1 {
    margin: auto;
    width: 100%;
    margin-top: 5px;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.5), inset 0px 4px 0 0 rgba(82, 151, 227, 0.9);
}

.board-numbers-layer-2 {
    flex: 1 1;
    border-radius: 4px;
    background-color: #060417;
    background-image: url(../images/gold-border.png);
    background-size: calc(100% + 25px) calc(100% + 23px);
    background-position: -12px -9px;
    padding: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.board-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(6, 4, 23, 0.7);
    border-radius: 4px;

    .number-container {
        height: 33px;
        width: 33px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        text-align: center;
        border-radius: 7px;
        font-weight: bold;
        color: #170d45;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        outline: none;

        .ball-content {
            outline: none;
            background-image: url(../images/rounded-rectangle.png);
            background-repeat: no-repeat;
            background-size: 101% 101%;
            width: 95%;
            height: 95%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8em;
            border-radius: 7px;
        }

        &.selected {
            .ball-content {
                background-image: url(../images/selected-bg.png);
                background-size: 101% 101%;
                background-repeat: no-repeat;
                box-shadow: none;
            }
        }

        &.picked {
            .ball-content {
                box-shadow: none;
                background-color: transparent;
                background-image: url(../images/gray-ball.png);
                background-repeat: no-repeat;
                background-size: 101% 101%;
            }
        }

        &.hit {
            .ball-content {
                background-color: transparent;
                background-image: url(../images/gold-ball.png);
                background-repeat: no-repeat;
                color: #7f2a00;
                box-shadow: none;
                background-size: 101% 101%;
            }
        }
    }
}