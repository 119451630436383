.payout-table {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 125px;

  .header {
    color: #29b8ec;
    font-weight: bold;
    font-family: Roboto;
    font-size: 0.7em;
    padding: 3px 0;
    padding-top: 6px;
    text-transform: uppercase;
    background-color: #07031a;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 4px 4px 0 0;

    .border {
        width: 100%;
        height: 2px;
        box-shadow: inset 0px -2px 2px 0 rgba(250, 238, 126, 0.35);
        background-image: linear-gradient(to right, #4e2d49 20%, #b47d3f, #dbbe3c, #fcf184, #dbbe3c, #b47d3f, #4e2d49 85%);
    }
  }

  .data-groups {
    display: flex;

    .table {
      width: 100%;

      &:nth-child(2) {
        margin-left: 5px;
      }
    }

    .group {
      display: flex;
      border-radius: 0 0 4px 4px;
      overflow: hidden;

      .group-header {
        padding: 5px;
        flex: 1 1;
        text-align: center;
        font-family: Roboto;
        font-weight: 700;
        font-size: 0.7em;
        color: #ffffff;
        background-color: #3123bd;
        text-transform: uppercase;
      }
    }

    .data-list {
      .item {
        text-align: center;
        background-color: rgba(18, 31, 63, 0.5);
        color: #ffffff;
        margin: 1px 0;
        padding: 3px;
        overflow: visible;
        display: flex;
        justify-content: space-around;
        border-radius: 4px 0 0 4px;

        & > div {
          flex: 1;
          text-align: center;
          font-size: 0.7em;
        }
      }
    }
  }
}