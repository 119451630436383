.switch {
  background: #0b071e;
  height: 30px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  user-select: none;
  cursor: pointer;

  &.reverse {
    flex-direction: row-reverse;

    .dot {
      background-color: red;
    }
  }

  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #88d523;
  }
}